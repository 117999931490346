export const environment = {
  production: true,
//  api_url: 'https://stagapi.travelhealth-companion.com/api/v1/',
  api_url: 'https://api.travelhealth-companion.com/api/v1/',
 //api_url: 'http://localhost:5001/api/v1/',
 redirectURL:'https://travelhealth-companion.com/login',
 userredirectURL:'https://dev.travelhealth-companion.com',
 linkedinClientId:"7817mi1monqcxi",
 linkedinScope:"r_liteprofile%20r_emailaddress",
 facebookId:"829282234382140",
 googleId:"1026759770456-pdb05gdgqcr814rpp2vlaltbjrnn5th6.apps.googleusercontent.com",
clientWebsiteurl:"https://www.fitfortravel.nhs.uk/home",
local_url:'http://localhost:8080/'

};
