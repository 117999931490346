import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiService } from 'src/app/shared/services/api.httpservice';
import { HOME_ENDPOINTS } from '../constants/home-endpoints.constants';
import { CategoryApiResponse } from '../interfaces/category.interface';
import {
  QuestionsApiResponse,
  QuestionsUsersApiResponse,
  QuestionUser,
} from '../interfaces/questions.interface';
import { VideoApiResponse } from '../interfaces/videos.interface';
import { TrainerApiResponse } from '../interfaces/trainers.interface';
import {
  AssessmentApiResponse,
  AssessmentPDF,
} from '../interfaces/assessment.interface';
import {
  JournalApiRequestResponse,
  JournalApiResponse,
  newJournal,
} from '../interfaces/journals.interface';
import {
  PeriodCycleApiResponse,
  PeriodCycleData,
  PeriodCycleMeta,
  periodCycleApiRequestResponse,
} from '../interfaces/period-cycle.interface';
import {
  PaymentApiResponse,
  UserProfileApiResponse,
} from '../interfaces/common.interface';
import {
  ReminderContext,
  RemindersApiResponse,
  ReminderSuccess,
} from '../interfaces/reminders.interface';
import { PackagesApiResponse } from '../interfaces/packages.interface';

const endpoints = HOME_ENDPOINTS;

@Injectable({ providedIn: 'root' })
export class HomeApiService {
  private readonly limitParam = [
    {
      param: 'limit',
      value: '1000',
    },
  ];

  constructor(private readonly apiService: ApiService) {}

  public getUserProfile(): Observable<UserProfileApiResponse> {
    return this.apiService.get('user/profile');
  }

  public getCategories(): Observable<CategoryApiResponse> {
    return this.apiService.get(endpoints.categories.list, {}, this.limitParam);
  }

  public getAssessments(): Observable<AssessmentApiResponse> {
    return this.apiService.get(endpoints.assessments.list, {}, this.limitParam);
  }

  public getAssessmentByCategoryId(
    categoryId: string | undefined
  ): Observable<AssessmentApiResponse> {
    return this.apiService.get(endpoints.assessments.list, {}, [
      ...this.limitParam,
      { param: 'categoryId', value: categoryId || '' },
    ]);
  }

  public getQuestions(assessmentId: string): Observable<QuestionsApiResponse> {
    return this.apiService.get(endpoints.questions.list, {}, [
      ...this.limitParam,
      {
        param: 'assessmentId',
        value: assessmentId,
      },
    ]);
  }

  public getQuestionsUsers(
    assessmentId: string
  ): Observable<QuestionsUsersApiResponse> {
    return this.apiService.get(endpoints.questionsUsers.list, {}, [
      ...this.limitParam,
      {
        param: 'assessmentId',
        value: assessmentId,
      },
    ]);
  }

  public addQuestionsUsers(questionsUser: {
    values: QuestionUser[];
  }): Observable<QuestionsUsersApiResponse> {
    return this.apiService.post(
      `${endpoints.questionsUsers.root}/multiple`,
      questionsUser
    );
  }

  public updateQuestionsUsers(questionsUser: {
    values: { _id: string; answer: string | number }[];
  }): Observable<QuestionsUsersApiResponse> {
    return this.apiService.put(
      `${endpoints.questionsUsers.updateMany}`,
      questionsUser
    );
  }

  public generateAssessmentPdfById(
    assessmentId: string,
    answerId: string
  ): Observable<AssessmentPDF> {
    return this.apiService.post(
      `${endpoints.assessments.root}/pdf?assessmentId=${assessmentId}`,
      { answerId }
    );
  }

  public deleteAnswers(questionsUsersIds: string[]): Observable<any> {
    return this.apiService.delete(`${endpoints.questionsUsers.deleteMany}`, {
      body: {
        questionsUsersIds,
      },
    });
  }

  public getVideos(): Observable<VideoApiResponse> {
    return this.apiService.get(endpoints.videos.list, {}, this.limitParam);
  }

  public getTrainers(): Observable<TrainerApiResponse> {
    return this.apiService.get(endpoints.trainers.list, {}, this.limitParam);
  }

  public getJournal(): Observable<JournalApiResponse> {
    return this.apiService.get(endpoints.journal.list, {}, this.limitParam);
  }

  public addJournal(
    journal: newJournal
  ): Observable<JournalApiRequestResponse> {
    return this.apiService.post(`${endpoints.journal.root}`, journal);
  }

  public updateJournal(
    journalId: string,
    journal: newJournal
  ): Observable<JournalApiRequestResponse> {
    return this.apiService.put(
      `${endpoints.journal.root}/${journalId}`,
      journal
    );
  }

  public deleteJournal(
    journalId: string
  ): Observable<JournalApiRequestResponse> {
    return this.apiService.delete(`${endpoints.journal.root}/${journalId}`);
  }

  public getCyclePeriodList(): Observable<PeriodCycleApiResponse> {
    return this.apiService.get(endpoints.cyclePeriod.list, {}, this.limitParam);
  }

  public addPeriodCycle(
    periodCycle: PeriodCycleData
  ): Observable<periodCycleApiRequestResponse> {
    return this.apiService.post(endpoints.cyclePeriod.root, periodCycle);
  }

  public updatePeriodCycle(
    periodCycleId: string,
    periodCycle: PeriodCycleMeta
  ): Observable<periodCycleApiRequestResponse> {
    return this.apiService.put(
      `${endpoints.cyclePeriod.root}/${periodCycleId}`,
      periodCycle
    );
  }

  public deletePeriodCycle(
    periodCycleId: string
  ): Observable<periodCycleApiRequestResponse> {
    return this.apiService.delete(
      `${endpoints.cyclePeriod.root}/${periodCycleId}`
    );
  }

  public getPeriodCycleInfo(
    date: string
  ): Observable<periodCycleApiRequestResponse> {
    return this.apiService.get(endpoints.cyclePeriod.getCyclePeriod, {}, [
      { param: 'startDate', value: date },
    ]);
  }

  public getReminders(): Observable<RemindersApiResponse> {
    return this.apiService.get(endpoints.reminders.list, {}, this.limitParam);
  }

  public setReminder(
    reminderContext: ReminderContext
  ): Observable<ReminderSuccess> {
    return this.apiService.post(endpoints.reminders.root, reminderContext);
  }

  public getPackages(): Observable<PackagesApiResponse> {
    return this.apiService.get(endpoints.packages.list, {}, this.limitParam);
  }

  public payments(packageId: string): Observable<PaymentApiResponse> {
    return this.apiService.post(endpoints.payments, { packageId });
  }
}
