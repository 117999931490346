import {
  Injectable,
  Signal,
  WritableSignal,
  computed,
  signal,
} from '@angular/core';

import { catchError, filter, map, Observable, of } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { HomeApiService } from './home-api.service';
import { Journal } from '../interfaces/journals.interface';
import { Category } from '../interfaces/category.interface';
import { Assessment } from '../interfaces/assessment.interface';
import { PeriodCycle } from '../interfaces/period-cycle.interface';
import { Trainer } from '../interfaces/trainers.interface';
import { Video } from '../interfaces/videos.interface';
import { Reminder } from '../interfaces/reminders.interface';
import { Package } from '../interfaces/packages.interface';
import {
  AnswersGrouped,
  QuestionSimplified,
} from '../interfaces/questions.interface';
import {
  UserProfile,
  UserProfileApiResponse,
} from '../interfaces/common.interface';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class HomeSignalsService {
  public isSubscribed: WritableSignal<boolean> = signal<boolean>(false);
  private urlSegments: WritableSignal<string[]> = signal<string[]>([]);
  public isMenstruationTrackerVisible: WritableSignal<boolean> =
    signal<boolean>(false);
  public journals: WritableSignal<Journal[]> = signal<Journal[]>([]);
  public categories: WritableSignal<Category[]> = signal<Category[]>([]);
  public assessments: WritableSignal<Assessment[]> = signal<Assessment[]>([]);
  public selectedAssessment: WritableSignal<Assessment> = signal<Assessment>(
    {} as Assessment
  );
  public trainers: WritableSignal<Trainer[]> = signal<Trainer[]>([]);
  public videos: WritableSignal<Video[]> = signal<Video[]>([]);
  public reminders: WritableSignal<Reminder[]> = signal<Reminder[]>([]);
  public packages: WritableSignal<Package[]> = signal<Package[]>([]);
  public periodCyclesResponse: WritableSignal<PeriodCycle[]> = signal<
    PeriodCycle[]
  >([]);

  public urlSegmentsSignal: Signal<string[]> = computed(() =>
    this.urlSegments()
  );

  public assessmentQuestions: WritableSignal<QuestionSimplified[]> = signal<
    QuestionSimplified[]
  >([]);

  public assessmentAnswers: WritableSignal<AnswersGrouped[]> = signal<
    AnswersGrouped[]
  >([]);

  public assessmentParentCategory: WritableSignal<Category> = signal<Category>(
    {} as Category
  );

  private readonly signalMap = {
    journals: this.journals,
    categories: this.categories,
    assessments: this.assessments,
    selectedAssessment: this.selectedAssessment,
    trainers: this.trainers,
    videos: this.videos,
    packages: this.packages,
    periodCycles: this.periodCyclesResponse,
    reminders: this.reminders,
  };
  private subscriptionCheckComplete: boolean = false;

  constructor(private readonly homeApiService: HomeApiService) {}

  public setIsSubscribed(): Observable<boolean> {
    return this.homeApiService.getUserProfile().pipe(
      untilDestroyed(this),
      map(
        ({ responseData: { userProfile } }: UserProfileApiResponse) =>
          userProfile
      ),
      map(({ isSubscribed, menstruationTracker }: UserProfile) => {
        this.isSubscribed.set(isSubscribed);
        this.isMenstruationTrackerVisible.set(menstruationTracker);
        this.subscriptionCheckComplete = true;

        return isSubscribed;
      })
    );
  }

  public getIsSubscribed(): boolean {
    return this.isSubscribed();
  }

  public isSubscriptionCheckComplete(): boolean {
    return this.subscriptionCheckComplete;
  }

  public initiateSubscription<T, R, P>(
    apiCall: (param?: P) => Observable<R>,
    dataExtractor: (response: R) => T,
    signalKey: keyof typeof this.signalMap,
    param?: P
  ): void {
    apiCall(param)
      .pipe(
        untilDestroyed(this),
        map(dataExtractor),
        filter((data) => !!data),
        catchError((_) => of(null as T))
      )
      .subscribe((data: any) => this.signalMap[signalKey].set(data));
  }

  public getSelectedTrainer(route: string): Trainer | undefined {
    return this.trainers().find(({ _id }: Trainer) => _id === route);
  }

  public getCategoryByName = (
    categoryTitle: string,
    restrictToRootCategories: boolean = true
  ): Category | undefined =>
    this.categories()
      .filter((category) =>
        restrictToRootCategories ? !category.parent : category
      )
      .find((category) => category.name === categoryTitle);

  public setURLSegments(urlSegments: string[]): void {
    this.urlSegments.set(urlSegments);
  }
}
